<template>
	<div class="container mt-4 mb-4" v-if="isLoaded">
		<div class="row">
			<div class="col-12">
				<h1>Política de Privacidade</h1>
				<p>(Actualizada a 01/06/2022)</p>
				<p>
					A sua privacidade e a segurança dos seus dados são importantes para nós.
				</p>
				<p>
					Esta Política, em conjunto com a nossa <a :href="cookiesLink" target="_blank"
															  rel="noopener">Política de
					Cookies</a> e os <a :href="termsLink" target="_blank" rel="noopener">Termos e Condições</a>,
					demonstram o
					nosso
					compromisso de proteger e respeitar a sua privacidade e estabelecem as normas pelas quais se rege o
					tratamento dos dados que nos são fornecidos.
				</p>
				<p>
					Esta Política aplica-se apenas ao nosso site, se sair do mesmo, por exemplo, através de um link,
					passará
					a estar sujeito à política de privacidade do responsável por esse outro website. Não temos controlo
					sobre políticas ou termos de outros sites.
				</p>
				<p>
					Recomendamos que leia este documento atentamente, pois dele constam todas as informações que
					necessita
					para saber que dados pessoais tratamos e como o fazemos.
				</p>

				<p class="strong">
					1. OBJECTO
				</p>
				<p>
					A presente Política de Privacidade aplica-se à recolha e tratamento dos dados pessoais fornecidos
					pelos
					utilizadores que acedem a este site.
				</p>

				<p class="strong">
					2. ENQUADRAMENTO LEGAL
				</p>
				<p>
					A presente Política de Privacidade foi executada de acordo com os diplomas legais aplicáveis em
					matéria
					de protecção de dados, nomeadamente, o Regulamento 2016/679/UE, do Parlamento Europeu e do Conselho,
					de
					27 de abril de 2016 (doravante RGPD) e respectiva legislação nacional de execução (Lei n.º 58/2019
					de 8
					de Agosto e Lei n.º 59/2019, de 8 de Agosto).
				</p>

				<p class="strong">
					3. RESPONSÁVEL PELO TRATAMENTO
				</p>
				<p>
					A YNEXUS DIGITAL TRIBE, LDA, doravante apenas Ynexus, é Responsável pelo Tratamento dos dados
					pessoais
					que recolhe quando determina:
				</p>
				<ul>
					<li>Quais os Dados Pessoais que devem ser tratados;</li>
					<li>Quais as Finalidades para as quais os Dados Pessoais são tratados; e,</li>
					<li>Quais os meios a aplicar para o tratamento dos Dados Pessoais.</li>
				</ul>

				<p class="strong">
					4. RESPONSÁVEL PELO TRATAMENTO DOS DADOS FORNECIDOS NO FORMULÁRIO DE CONTACTO DESTE SITE
				</p>

				<p>
					Quanto aos dados pessoais recolhidos neste site, por conta da {{ clientName }} através do
					formulário de
					contacto, no âmbito desta campanha de marketing, a Ynexus age, quanto aos mesmos, como
					Subcontratante.
				</p>
				<p>
					No que diz respeito a estes dados pessoais, {{ clientName }} é a Responsável pelo Tratamento,
					recomendando-se, para informações quanto à utilização dos seus dados pessoais pela mesma, que
					solicite a
					respectiva Politica de Privacidade pelo seguinte email <a :href="'mailto:' + clientEmail">{{
						clientEmail
					}}</a>
				</p>

				<p class="strong">
					5. PRINCÍPIOS APLICÁVEIS AO TRATAMENTO DE DADOS
				</p>
				<p>
					A Ynexus compromete-se, quanto ao tratamento de dados pessoas, a agir em conformidade com os
					preceitos
					legalmente aplicáveis, assegurando, nomeadamente:
				</p>
				<ol type="a">
					<li>A Transparência, lealdade e legalidade no tratamento;</li>
					<li>
						Que os dados pessoais serão recolhidos e tratados apenas para fins especificados, explícitos e
						legítimos, em consonância com os objetivos definidos.
					</li>
					<li>
						Que limitará o processamento de dados pessoais ao o que é adequado, relevante e necessário
						relativamente
						às finalidades para as quais são tratados.
					</li>
					<li>Que os dados pessoais devem estar correctos, completos e actualizados.</li>
					<li>
						Que os dados serão conservados de forma a permitir a identificação dos seus titulares apenas
						durante
						o
						período estritamente necessário e dentro do legalmente permitido para a prossecução das
						finalidades
						de
						recolha ou do tratamento posterior.
					</li>
				</ol>

				<p class="strong">
					6. FINALIDADES DO TRATAMENTO DE DADOS PESSOAIS
				</p>
				<p>
					Os dados recolhidos e tratados pela Ynexus destinam-se, designadamente, a:
				</p>
				<ul>
					<li>Cumprimento de Obrigações Legais e/ou Contratuais;</li>
					<li>Marketing.</li>
				</ul>

				<p class="strong">
					7. CATEGORIAS DE DADOS PESSOAIS
				</p>
				<p>
					A Ynexus trata, nomeadamente, as seguintes categorias de dados pessoais:
				</p>
				<ul>
					<li>
						Dados de identificação pessoal e dados de contacto: nome, morada, contacto telefónico e
						e-mail;
					</li>
					<li>Dados demográficos: cidade de residência, código postal;</li>
					<li>Dados referentes a veículos ( matrícula, preferências de modelo/marca,, etc.);</li>
					<li>
						Dados sobre a sua utilização de sistemas por nós oferecidos (por exemplo, tempo de acesso ao
						nosso
						website).
					</li>
				</ul>

				<p class="strong">
					8. FUNDAMENTO JURÍDICO DO TRATAMENTO DE DADOS PESSOAIS
				</p>
				<p>
					A Ynexus só trata Dados Pessoais quando exista um fundamento de licitude que legitime esse
					tratamento, nomeadamente:
				</p>
				<ul>
					<li>Consentimento do titular;</li>
					<li>Execução de um contrato;</li>
					<li>Cumprimento de uma obrigação jurídica;</li>
					<li>
						Interesses legítimos de desenvolvimento e crescimento da actividade do responsável pelo
						tratamento.
					</li>
				</ul>
				<p>
					No momento da recolha dos seus dados pessoais pediremos o seu consentimento, sempre que tal for
					necessário para legitimar o tratamento dos seus dados. No caso de consentir no tratamento dos
					seus
					dados, tem o direito de retirar o seu consentimento em qualquer altura.
				</p>

				<p class="strong">
					9. DURAÇÃO DO TRATAMENTO DE DADOS PESSOAIS
				</p>
				<p>
					Os dados pessoais são conservados apenas durante o período de tempo necessário para a realização das
					finalidades para as quais são tratados.
				</p>
				<p>
					Sempre que aplicável, a Ynexus cumpre os prazos de conservação legalmente estabelecidos, todavia
					existem
					situações em que a mesma pode ser obrigada a conservar alguns Dados por um período mais longo,
					atendendo
					por exemplo a determinadas obrigações legais, contratuais e/ou interesse legítimo.
				</p>
				<p>
					Assim que termine o período de necessidade de conservação, os dados serão eliminados, de forma
					segura,
					pela Ynexus.
				</p>

				<p class="strong">
					10. DIREITOS DOS TITULARES DOS DADOS
				</p>
				<p>
					O titular dos Dados Pessoais tem direito a solicitar, mediante pedido escrito:
				</p>
				<ol type="A">
					<li>
						<span>DIREITO DE ACESSO:</span> os titulares dos dados têm o direito de saber se estão, ou não,
						a ser
						tratados
						dados
						pessoais que lhes digam respeito, quais o tipo de dados em causa, qual a finalidade desse
						tratamento, se
						os dados foram transmitidos para terceiros e por quanto tempo serão armazenados;
					</li>
					<li>
						<span>DIREITO DE RECTIFICAÇÃO:</span> os titulares dos dados têm o direito a requerer a
						rectificação dos seus
						dados
						pessoais que estejam desatualizados, incorretos ou incompletos;
					</li>
					<li>
						<span>DIREITO AO ESQUECIMENTO:</span> os titulares dos dados têm o direito de solicitar ao
						responsável pelo
						tratamento de dados o apagamento dos seus dados se estes deixaram de ser necessários para o fim
						para
						o
						qual foram recolhidos, se foi retirado o consentimento (quando para o tratamento já não exista
						fundamento), se houver oposição ao tratamento (inexistindo interesse legitimo prevalecente), se
						os
						dados
						foram alvo de tratamento ilícito.
					</li>
					<li>
						<span>DIREITO A RETIRAR O CONSENTIMENTO:</span> se tiverem dado o seu consentimento à utilização
						dos seus
						dados
						pessoais, os titulares têm o direito de retirar o seu consentimento em qualquer momento (a
						retirada
						do
						consentimento não compromete a licitude do tratamento efectuado com base no consentimento
						previamente
						dado), devendo comunicar a sua intenção através dos contatos fornecidos abaixo;
					</li>
					<li>
						<span>DIREITO À LIMITAÇÃO DO TRATAMENTO:</span> os titulares dos dados têm o direito de exigir a
						limitação do
						tratamento nas seguintes situações:
						Os dados não são exactos;
						o titular não quer que os dados sejam apagados;
						os dados já não são necessários para a finalidade original, mas ainda não podem ser apagados por
						razões
						jurídicas;
						enquanto se aguarda a decisão sobre a objeção do titular ao tratamento.
					</li>
					<li>
						<span>DIREITO DE PORTABILIDADE DOS DADOS:</span> os titulares de dados têm o direito de receber,
						mediante
						solicitação, uma cópia dos dados que fornecidos de transmitir esses dados para outro responsável
						pelo
						tratamento.
					</li>
					<li>
						<span>DIREITO DE OPOSIÇÃO:</span> os titulares dos dados têm o direito de se oporem, em qualquer
						momento, ao
						tratamento dos dados pessoais que lhes digam respeito. Uma vez exercido esse direito, o
						tratamento
						dos
						dados cessará imediatamente, a menos que o responsável pelo tratamento apresente razões
						ponderosas e
						legítimas que prevaleçam sobre os interesses, direitos e liberdade dos titulares dos dados, ou
						por
						razões de exercício de defesa num processo judicial.
					</li>
					<li>
						<span>DIREITO DE RECLAMAÇÃO:</span> Os titulares dos dados têm o direito de apresentar
						reclamação junto da
						CNPD
						(autoridade de controlo), cujos contacto se encontram no site www.cnpd.pt.
					</li>
				</ol>
				<p>
					A Ynexus informa que o exercício dos direitos acima referido pode ser limitado, face à existência de
					direitos e liberdades de terceiros, de obrigações legais ou de confidencialidade e de interesses
					legítimos prevalecentes da Ynexus ou de terceiro.
				</p>

				<p>
					<strong>COMO EXERCER OS SEUS DIREITOS:</strong> Pode exercer os seus direitos através de E-mail para
					o seguinte
					endereço
					de e-mail: <a href="mailto:geral@ynexus.com">geral@ynexus.com</a> ou para a morada Startup Leiria,
					R. da Carvalha 570, 2400-441 Leiria, sempre colocando em assunto “Dados Pessoais”.
				</p>

				<p class="strong">
					11. PARTILHA DE DADOS
				</p>
				<p>
					Os Dados Pessoais recolhidos e tratados pela Ynexus podem ser partilhados com as seguintes
					entidades:
				</p>
				<ul>
					<li>
						Subcontratantes, os quais estão vinculadas à Ynexus por contrato escrito, apenas podendo tratar
						Dados
						Pessoais para os fins especificamente estabelecidos;
					</li>
					<li>
						Obtendo o seu consentimento explícito, também podemos transmitir as suas informações ao nosso
						cliente
						sob obrigação contratual, para permitir que ele processe os dados conforme necessário e conforme
						acordado com a Ynexus;
					</li>
					<li>
						Autoridades judiciais, administrativas, de supervisão ou regulatórias, em cumprimento de
						obrigações
						legais e/ou contratuais.
					</li>
				</ul>

				<p class="strong">
					12. TRANSFERÊNCIAS INTERNACIONAIS DE DADOS PESSOAIS
				</p>
				<p>
					Caso a Ynexus opere qualquer transferência de dados pessoais para um País não pertencente ao Espaço
					Económico Europeu garante-se, desde já, que a mesma apenas terá lugar se, de acordo com as melhores
					práticas, recomendações da autoridade de protecção de dados e Legislação em vigor:
				</p>
				<ol type="a">
					<li>o País em questão assegurar um adequado nível de proteção;</li>
					<li>forem adotadas as medidas ou contratos com as salvaguardas necessárias.</li>
				</ol>
				<p>
					O titular dos dados terá conhecimento das transferências de dados pessoais que lhe digam
					directamente
					respeito, no momento da recolha dos dados ou, quando tal não seja possível, antes da realização da
					transferência.
				</p>

				<p class="strong">
					13. LINKS PARA SITES DE TERCEIROS
				</p>
				<p>
					Este site pode conter links para sites de terceiros, que podem ter políticas de privacidade
					diferentes
					das nossas, pelo que não somos responsáveis pelos mesmos e recomendamos a consulta das respectivas
					políticas.
				</p>

				<p class="strong">
					14. COOKIES
				</p>
				<p>
					A Ynexus utiliza cookies nos seus sites para melhorar a experiência do utilizador e permitir
					realizar
					determinadas operações de forma segura. Consulte, por favor, aqui a informação respeitante à
					<a :href="cookiesLink" target="_blank" rel="noopener">Política de Cookies</a>.
				</p>

				<p class="strong">
					15. SEGURANÇA DOS DADOS PESSOAIS
				</p>
				<p>
					A Ynexus adopta, segundo as melhores práticas, as medidas técnicas e organizativas adequadas para
					proteger os Dados Pessoais que le são confiados contra a sua perda, destruição ou danificação,
					acidental
					ou ilícita e, bem assim, para assegurar que os dados facultados sejam protegidos contra o acesso ou
					utilização por parte de terceiros não autorizados.
				</p>

				<p class="strong">
					16. ALTERAÇÕES À POLÍTICA DE PRIVACIDADE
				</p>
				<p>
					A presente Política de Privacidade poderá ser alterada, mediante publicação neste site, sem
					necessidade
					de consentimento prévio e expresso do titular dos dados.
				</p>

				<p>
					Caso tenha alguma questão ou pretenda obter mais informações acerca de como tratamos os seus Dados
					Pessoais, e quais as nossas práticas em matéria de segurança da informação, por favor, não hesite em
					contactar-nos através do <a href="mailto:geral@ynexus.com">geral@ynexus.com</a> ou para a morada
					Startup Leiria, R. da Carvalha 570, 2400-441 Leiria.
				</p>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	name       : 'PrivacyPage',
	components : {},
	data() {
		return {
			isLoaded    : false,
			clientName  : String,
			clientEmail : String,
			cookiesLink : String,
			termsLink   : String
		};
	},
	mounted() {
		let res = this.$store.state.resData.data;
		if (res.setup.privacy_name !== null && res.setup.privacy_email !== null && res.setup.privacy_address !== null) {
			this.isLoaded    = true;
			this.clientName  = res.setup.privacy_name;
			this.clientEmail = res.setup.privacy_email;
			this.cookiesLink = `${res.full_url}/politica-cookies/${this.$route.params.seller_id}`;
			this.termsLink   = `${res.full_url}/termos-condicoes/${this.$route.params.seller_id}`;
		} else {
			window.location.href = res.redirect_url;
		}
	},
	methods : {}
};
</script>

<style lang="scss">
h1 {
	font-size: 1.6rem;
	font-weight: 700;
}

p, li {
	font-size: 0.85rem;

	&.strong {
		font-weight: 700;
		margin-top: 2rem;
	}
}

a {
	color: #343942;
	text-decoration: underline;
}

ol {
	li {
		span {
			text-decoration: underline;
		}
	}
}
</style>
